<template>
  <cui-acl :roles="['HRseeUsersAll', 'HRseeUsersDepartment', 'HRseeUsersProject', 'HRseeUsersProjectDepartment']" redirect>
    <h4>Timeliste</h4>
    <div class="filters">
      <div class="row">
        <div class="col-md-3">
          <a-form-item label="Periode">
            <a-week-picker placeholder="Select week" @change="changeWeek" format="[Uke] wo" v-model="date"/>
          </a-form-item>
        </div>
        <div class="col-md-3" v-if="['HRseeUsersAll', 'HRseeUsersDepartment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          <a-form-item label="Prosjekt">
            <a-select :options="projects" v-model="selectedProject"  @change="getHours" style="width: 150px" />
          </a-form-item>
        </div>
        <div class="col-md-3" v-if="['HRseeUsersAll', 'HRseeUsersProject'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          <a-form-item label="Avdeling">
            <a-select :options="departments" v-model="selectedDepartment" @change="getHours" style="width: 150px;" />
          </a-form-item>
        </div>
        <div class="col-md-3">
          <a-form-item label=":">
            <a-checkbox v-model="showWeekend">Vis helg</a-checkbox>
          </a-form-item>
        </div>
      </div>
    </div>
    <div class="row">
      <a-table :columns="columns" :data-source="data" bordered style="width: 100%">
        <timeSheetCell />
        <template slot="approveHours" slot-scope="text, record">
          <a-checkbox @change="approveHours(record)" v-model="record.approveHours" :disabled="record.disableApprove"></a-checkbox>
        </template>
        <template slot="footer">
          Totalt: {{ data.reduce((acc, val) => acc + val.sum, 0) }} timer
        </template>
      </a-table>
    </div>
    <a-diider />
    <div class="row">
      <a-table :columns="summaryColumns" :data-source="summarizedData" bordered style="width: 100%">
      </a-table>
    </div>
  </cui-acl>
</template>

<script>
import Vue from 'vue'
import cuiAcl from '@/components/cleanui/system/ACL'
import moment from 'moment'
import { firestore } from '@/services/firebase'
import timeSheetCell from '@/components/custom/visual/timeSheetCell'

export default {
  components: {
    timeSheetCell,
    cuiAcl,
  },
  data() {
    return {
      data: [],
      date: moment(),
      week: moment().format('GGGG-[W]WW'),
      selectedDepartment: this.$store.state.user.department,
      selectedProject: this.$store.state.user.project,
      timesheetSettings: {},
      projects: [],
      departments: [],
      showWeekend: false,
      summaryColumns: [],
      summarizedData: [],
    }
  },
  computed: {
    columns() {
      return this.generateColumns()
    },
    dateArray() {
      return this.generateDateArray()
    },
  },
  async mounted() {
    this.getOptions()
    await this.getEmployees()
    this.getHours()
    this.getProjects()
    this.getDepartments()
  },
  watch: {
    data: {
      handler(newValue) {
        newValue.forEach((employee, index) => {
          const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek')
          const endOfWeek = moment(this.week, 'GGGG-[W]WW', true).endOf('isoWeek')
          let sum = 0
          for (let day = startOfWeek; day.isSameOrBefore(endOfWeek); day.add(1, 'days')) {
            const formattedDate = day.format('YYYY-MM-DD')
            if (Array.isArray(employee[formattedDate])) {
              sum += employee[formattedDate].reduce((total, hoursObject) => total + hoursObject.hours, 0)
            }
          }
          this.data[index].sum = sum
        })
        // check forEach employee if all hours are approved in such case set approve to true
        this.checkApprovalState()
        // summmarize data by activity and day
        this.summarizeData()
        this.generateSummaryColumns()
      },
      deep: true, // This makes the watcher observe nested data
    },
  },
  methods: {
    generateSummaryColumns() {
      const cols = this.columns.map((col) => {
        return {
          title: col.title,
          dataIndex: col.dataIndex,
          key: col.key,
          customRender: (text, record) => {
            return text
          },
        }
      })
      cols.splice(0, 1)
      cols.splice(-1, 1)
      // add first column with title activity
      cols.unshift({
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
      })
      this.summaryColumns = cols
      return true
    },
    summarizeData() {
      const summarizedData = []
      const activities = this.timesheetSettings.activities
      const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek')
      const endOfWeek = moment(this.week, 'GGGG-[W]WW', true).endOf('isoWeek')
      // summarize data by activity (row( and day (column)
      activities.forEach((activity) => {
        const activityRow = {
          activity: activity,
        }
        for (let day = startOfWeek; day.isSameOrBefore(endOfWeek); day.add(1, 'days')) {
          const formattedDate = day.format('YYYY-MM-DD')
          const daySum = this.data.reduce((acc, employee) => {
            if (Array.isArray(employee[formattedDate])) {
              const daySum = employee[formattedDate].reduce((total, hoursObject) => {
                if (hoursObject.activity === activity) {
                  return total + hoursObject.hours
                }
                return total
              }, 0)
              return acc + daySum
            }
            return acc
          }, 0)
          activityRow[formattedDate] = daySum
        }
        summarizedData.push(activityRow)
      })
      console.log(summarizedData)
      this.summarizedData = summarizedData
    },
    checkApprovalState() {
      this.data.forEach((employee, i) => {
        const daysWithHours = Object.values(employee)
          .filter((day) => Array.isArray(day) && day.length > 0) // Ensure there are days with hours
        let approved = daysWithHours.length > 0 // Start with true if there are days with hours, false otherwise

        if (approved) { // Only proceed if there are days with hours
          approved = daysWithHours.every((day) =>
            day.every((hoursObject) =>
              hoursObject.approvals && hoursObject.approvals.some((approval) => approval.status === 'approved'),
            ),
          )
        }
        this.data[i].approveHours = approved
        this.data[i].disableApprove = false
      })
    },
    generateColumns() {
      const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek')
      const dayRange = this.showWeekend ? [1, 2, 3, 4, 5, 6, 7] : [1, 2, 3, 4, 5] // Exclude 6 and 7 if showWeekend is false
      const cols = [
        {
          title: 'Ansatt',
          dataIndex: 'employee',
          key: 'employee',
        },
        ...dayRange.map(offset => {
          const day = startOfWeek.clone().add(offset - 1, 'days')
          const formattedDate = day.format('YYYY-MM-DD')
          return {
            title: `${day.format('dd')} ${day.format('DD')}`,
            dataIndex: formattedDate,
            key: formattedDate,
            customRender: (text, record) => {
              return <timeSheetCell text={text} record={record} columnKey={formattedDate} onUpdateHours={this.getHours} timesheetSettings={this.timesheetSettings} />
            },
          }
        }),
        {
          title: 'Sum',
          dataIndex: 'sum',
          key: 'sum',
          scopedSlots: { customRender: 'sum' },
        },
      ]
      if (['timesheetApproval'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        cols.push({
          title: 'Godkjenn',
          dataIndex: 'approveHours',
          key: 'approveHours',
          scopedSlots: { customRender: 'approveHours' },
        })
      }
      return cols
    },
    generateDateArray() {
      const dates = []
      const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek')
      const endOfWeek = startOfWeek.clone().endOf('isoWeek')
      for (let m = moment(startOfWeek); m.diff(endOfWeek, 'days') <= 0; m.add(1, 'days')) {
        dates.push(m.format('YYYY-MM-DD'))
      }
      return dates
    },
    changeWeek(date, dateString) {
      this.week = moment(date).format('GGGG-[W]WW')
      this.getHours() // Ensure this method uses updated dateArray from computed property
    },
    async approveHours(record) {
      record.disableApprove = true
      const approve = record.approveHours
      const approval = {
        employeeID: this.$store.state.user.employeeID,
        approvalDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        status: 'approved',
      }
      console.log(this.dateArray)
      for (const date of this.dateArray) {
        // Check if there's any entry for the date, and create a new record if not
        if (!record[date] || record[date].length === 0) {
          if (approve) { // Only proceed if the intention is to approve
            const newRecord = {
              employeeID: record.employeeID,
              date,
              hours: 0,
              activity: 'none',
              approvals: [approval],
            }
            const docRef = await firestore.collection('timesheets').add(newRecord)
            // Initialize the date array if it doesn't exist
            if (!record[date]) record[date] = []
            // Add the new hoursObject with Firestore document ID to the local data
            record[date].push({ ...newRecord, id: docRef.id })
          }
        } else {
          // Existing logic for handling hoursObjects on dates that have entries
          for (const hoursObject of record[date]) {
            if (approve) {
              await firestore.collection('timesheets').doc(hoursObject.id).update({ approvals: [approval] })
              Vue.set(hoursObject, 'approvals', [approval])
            } else {
              if (hoursObject.activity === 'none') {
                await firestore.collection('timesheets').doc(hoursObject.id).delete()
                const index = record[date].indexOf(hoursObject)
                if (index !== -1) record[date].splice(index, 1)
              } else {
                await firestore.collection('timesheets').doc(hoursObject.id).update({ approvals: [] })
                Vue.set(hoursObject, 'approvals', [])
              }
            }
          }
        }
      }
      record.disableApprove = false
      this.data[this.data.indexOf(record)].approveHours = approve
      this.checkApprovalState()
      this.$forceUpdate()
    },
    moment,
    async getHours() {
      // sum hours = 0
      await this.getEmployees()
      const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek').format('YYYY-MM-DD')
      const endOfWeek = moment(this.week, 'GGGG-[W]WW', true).endOf('isoWeek').format('YYYY-MM-DD')
      console.log(startOfWeek, endOfWeek)
      // array of strings from employees array
      const employeeIDs = this.data.map((object) => object.employeeID)

      // Split employeeIDs into chunks of up to 10 elements each
      const chunks = []
      for (let i = 0, j = employeeIDs.length; i < j; i += 10) {
        chunks.push(employeeIDs.slice(i, i + 10))
      }

      // For each chunk, make a Firestore query
      const promises = chunks.map(chunk =>
        firestore.collection('timesheets')
          .where('date', '>=', startOfWeek)
          .where('date', '<=', endOfWeek)
          .where('employeeID', 'in', chunk)
          .get(),
      )

      // Wait for all the queries to complete
      Promise.all(promises)
        .then(snapshots => {
          snapshots.forEach(snapshot =>
            snapshot.forEach(doc => {
              const docData = doc.data()
              const hoursObject = {
                id: doc.id,
                employeeID: docData.employeeID,
                date: docData.date,
                hours: docData.hours,
                activity: docData.activity,
                approvals: docData.approvals,
              }
              const employeeIndex = this.data.findIndex((object) => object.employeeID === docData.employeeID)
              this.data[employeeIndex][docData.date].push(hoursObject)
              this.data[employeeIndex].sum += docData.hours
            }),
          )
        })
        .catch(error => console.error('Error fetching documents:', error))
    },
    // getHoursMissingHours() {
    //   const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek').format('YYYY-MM-DD')
    //   const endOfWeek = moment(this.week, 'GGGG-[W]WW', true).endOf('isoWeek').format('YYYY-MM-DD')
    //   const employeeIDs = this.data.map((object) => object.employeeID)
    //   const chunks = []
    //   for (let i = 0, j = employeeIDs.length; i < j; i += 10) {
    //     chunks.push(employeeIDs.slice(i, i + 10))
    //   }

    //   // For each chunk, make a Firestore query
    //   const promises = chunks.map(chunk =>
    //     firestore.collection('timesheets')
    //       .where('date', '>=', startOfWeek)
    //       .where('date', '<=', endOfWeek)
    //       .where('employeeID', 'not-in', chunk)
    //       .get(),
    //   )

    //   Promise.all(promises)
    //     .then(snapshots => {
    //       snapshots.forEach(snapshot =>
    //         snapshot.forEach(doc => {
    //           const docData = doc.data()
    //           const hoursObject = {
    //             id: doc.id,
    //             employeeID: docData.employeeID,
    //             date: docData.date,
    //             hours: docData.hours,
    //             activity: docData.activity,
    //             approvals: docData.approvals,
    //           }
    //           const employeeIndex = this.data.findIndex((object) => object.employeeID === docData.employeeID)
    //           this.data[employeeIndex][docData.date].push(hoursObject)
    //           this.data[employeeIndex].sum += docData.hours
    //         }),
    //       )
    //     })
    //     .catch(error => console.error('Error fetching documents:', error))
    // },
    async addEmployee(employeeID) {
      const emmployee = await firestore.collection('users').doc(employeeID).get()
      const employeeData = {
        id: employeeID,
        name: emmployee.data().fullName,
        project: emmployee.data().project,
        department: emmployee.data().department,
        sum: 0,
        ...[1, 2, 3, 4, 5, 6, 7].reduce((acc, offset) => {
          const day = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek').clone().add(offset - 1, 'days')
          const formattedDate = day.format('YYYY-MM-DD')
          acc[formattedDate] = []
          return acc
        }, {}),
      }
      this.data.push(employeeData)
    },
    getOptions() {
      firestore.collection('settings').doc('timesheet')
        .get()
        .then((doc) => {
          const docData = doc.data()
          this.timesheetSettings = docData
        })
    },
    async getEmployees2() {
      this.data = []
      const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek').toDate()
      // const endOfWeek = moment(this.week, 'GGGG-[W]WW', true).endOf('isoWeek').toDate()

      let query = firestore.collection('positions')
        .where('endDate', '>=', startOfWeek)

      if (this.selectedProject) {
        query = query.where('projectID', '==', this.selectedProject)
      }

      if (this.selectedDepartment) {
        query = query.where('department', '==', this.selectedDepartment)
      }

      const snapshot = await query.get()
      const employees = []

      snapshot.forEach(doc => {
        const position = doc.data()
        const employee = {
          id: position.employeeID,
          name: `${position.fullName} (${position.percentage}%)`,
        }
        employees.push(employee)
        this.data.push({
          startDate: position.startDate,
          endDate: position.endDate,
          employeeID: employee.id,
          employee: employee.name,
          project: position.projectID,
          department: position.department,
          sum: 0,
          // add empty array for each day of the week. these shall have key of date YYYY-MM-DD not of 1234567
          ...[1, 2, 3, 4, 5, 6, 7].reduce((acc, offset) => {
            const day = startOfWeek.clone().add(offset - 1, 'days')
            const formattedDate = day.format('YYYY-MM-DD')
            acc[formattedDate] = []
            return acc
          }, {}),

        })
      })
    },
    async getEmployees() {
      this.data = []
      const startOfWeek = moment(this.week, 'GGGG-[W]WW', true).startOf('isoWeek')
      const endOfWeek = moment(this.week, 'GGGG-[W]WW', true).endOf('isoWeek').format('YYYY-MM-DD')
      let ref = await firestore.collection('users').where('status', '==', 'active').orderBy('fullName', 'asc')
      if (this.selectedDepartment) {
        ref = ref.where('department', '==', this.selectedDepartment)
      }
      if (this.selectedProject) {
        ref = ref.where('project', '==', this.selectedProject)
      }
      await ref
        .get()
        .then((querySnapshot) => {
          const employees = []
          querySnapshot.forEach((doc) => {
            const docData = doc.data()
            const percentage = docData.positions ? docData.positions[docData.positions.length - 1].percentage : 100
            const employee = {
              id: docData.employeeID,
              name: `${docData.fullName} (${percentage}%)`,
            }
            // check if employee had active position in the week based on start and end date
            const position = docData.positions ? docData.positions.find((pos) => {
              if (!pos.startDate) return false
              return moment(pos.startDate).isSameOrBefore(endOfWeek) && (moment(pos.endDate).isSameOrAfter(startOfWeek) || !pos.endDate)
            }) : null
            if (position) {
              employees.push(employee)
              // if position
              this.data.push({
                employeeID: docData.employeeID,
                employee: employee.name,
                project: this.selectedProject,
                department: this.selectedDepartment,
                sum: 0,
                // add empty array for each day of the week. these shall have key of date YYYY-MM-DD not of 1234567
                ...[1, 2, 3, 4, 5, 6, 7].reduce((acc, offset) => {
                  const day = startOfWeek.clone().add(offset - 1, 'days')
                  const formattedDate = day.format('YYYY-MM-DD')
                  acc[formattedDate] = []
                  return acc
                }, {}),

              })
            }
          })
        })
    },
    async getProjects() {
      firestore.collection('settings').doc('projects').collection('projects').where('company', 'in', this.$store.state.user.companies).get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const project = {
              label: doc.data().name,
              value: doc.data().id,
            }
            console.log(project)
            this.projects.push(project)
          })
        })
    },
    async getDepartments() {
      firestore.collection('settings').doc('departments').collection('departments').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const department = {
              label: doc.data().name,
              value: doc.data().id,
            }
            this.departments.push(department)
          })
        })
    },
  },
}
</script>
