<template>
  <div class="editable-cell">
    <div v-for="(timeactivity, i) in text" :key="i">
      <div v-if="timeactivity.editable" class="editable-cell-input-wrapper">
        <a-input v-model="timeactivity.hours" style="width: 40%" @pressEnter="() => check(timeactivity, i)" type="number" />
        <a-select v-model="timeactivity.activity" style="width: 40%" :options="timesheetSettings.activities" @click="() => check(timeactivity, i)" />
        <a-icon type="check" class="editable-cell-icon-check" @click="() => check(timeactivity, i)" />
      </div>
      <div v-else class="editable-cell-text-wrapper">
        <div v-if="timeactivity.activity != 'none'">
          {{ timeactivity.hours }}t {{ getActivityLabel(timeactivity.activity) }}
          <a-icon type="edit" class="editable-cell-icon" @click="() => editTimeactivity(i)" v-if="!approved"/>
          <a-icon type="delete" @click="() => deleteTimeactivity(i)" v-if="!approved"/>
        </div>
      </div>
    </div>
    <div class="editable-cell-text-wrapper" v-if="!approved">
      <i class="fa fa-plus" @click="addTimeactivity"></i>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import moment from 'moment'
import { firestore } from '@/services/firebase'
// import { openNotification } from '@/services/powerAutomate'

export default {
  props: {
    text: Array,
    record: Object,
    timesheetSettings: Object,
    columnKey: String,
    onUpdateHours: Function,
  },
  data() {
    return {
      approved: false,
    }
  },
  watch: {
    text: {
      handler() {
        this.checkApproval()
      },
      deep: true,
    },
  },
  methods: {
    checkApproval() {
      this.approved = false
      if (this.text.length > 0) {
        this.text.forEach(t => {
          if (t.approvals) {
            t.approvals.forEach(a => {
              if (a.status === 'approved') {
                this.approved = true
              }
            })
          }
        })
      }
    },
    editTimeactivity(index) {
      Vue.set(this.text[index], 'editable', true)
    },
    async check(timeactivity, index) {
      if (typeof timeactivity.hours === 'string') {
        timeactivity.hours = Number(timeactivity.hours.replace(',', '.'))
      }
      // const rulesPassed = await this.checkRules(timeactivity, index, this.columnKey, this.record.employeeID)
      // console.log('rulesPassed', rulesPassed)
      // if (!rulesPassed) return // checkRules will handle the error message
      const existingIndex = this.text.findIndex(t => t.activity === timeactivity.activity && t !== timeactivity)
      if (existingIndex !== -1) {
        Vue.set(this.text[existingIndex], 'hours', this.text[existingIndex].hours + timeactivity.hours)
        this.deleteTimeactivity(index) // Remove the current activity as its hours have been added to an existing activity
        this.updateFirestore(this.text[existingIndex])
      } else {
        Vue.set(timeactivity, 'editable', false)
        this.updateFirestore(timeactivity)
      }
    },
    addTimeactivity() {
      this.text.push({
        hours: this.timesheetSettings.default.hours,
        activity: this.timesheetSettings.default.activity,
        editable: true,
        date: this.columnKey,
        employeeID: this.record.employeeID,
        project: this.record.project,
        department: this.record.department,
      })
    },
    async updateFirestore(timeactivity) {
      const docRef = firestore.collection('timesheets')
      if (!timeactivity.id) {
        const doc = await docRef.add(timeactivity)
        console.log('Document written with ID: ', doc.id)
      } else {
        await docRef.doc(timeactivity.id).update(timeactivity)
        console.log('Document updated with ID: ', timeactivity.id)
      }
    },
    deleteTimeactivity(index) {
      const timeactivity = this.text[index]
      if (timeactivity.id) {
        firestore.collection('timesheets').doc(timeactivity.id).delete()
      }
      this.text.splice(index, 1)
    },
    getActivityLabel(activityValue) {
      const activity = this.timesheetSettings.activities.find(a => a.value === activityValue)

      return activity ? activity.label : 'Unknown activity'
    },
    // async checkRules(timeactivity, index, columnKey, employeeID) {
    //   const rule = this.timesheetSettings.activities.find(a => a.value === timeactivity.activity).rules || {}
    //   if (rule.maxTimein12months || rule.maxDaysinRow) {
    //     // get all timeactivities for the employeeid with the same activity in the last 12 months
    //     const twelveMonthsAgo = String(moment(columnKey).subtract(12, 'months').format('YYYY-MM-DD'))
    //     console.log('twelveMonthsAgo', twelveMonthsAgo)
    //     return firestore.collection('timesheets')
    //       .where('employeeID', '==', employeeID)
    //       .where('activity', '==', timeactivity.activity)
    //       .where('date', '>=', twelveMonthsAgo)
    //       .get()
    //       .then(querySnapshot => {
    //         const twelveMonthsActivities = []
    //         querySnapshot.forEach(doc => {
    //           twelveMonthsActivities.push(doc.data())
    //         })
    //         console.log('twelveMonthsActivities', (twelveMonthsActivities.length + 1) > rule.maxTimein12months)
    //         if (rule.maxTimein12months && (twelveMonthsActivities.length + 1) > rule.maxTimein12months) {
    //           openNotification(`You have exceeded the maximum time of ${rule.maxTimein12months} days in the last 12 months for the activity ${this.getActivityLabel(timeactivity.activity)}`)
    //           this.deleteTimeactivity(index)
    //           return false
    //         }
    //         if (rule.maxDaysinRow) {
    //           const sortedActivities = twelveMonthsActivities.sort((a, b) => moment(a.date) - moment(b.date))
    //           const lastDate = sortedActivities[sortedActivities.length - 1].date
    //           const days = moment(columnKey).diff(lastDate, 'days')
    //           if (days > rule.maxDaysinRow) {
    //             openNotification(`You have exceeded the maximum days in a row of ${rule.maxDaysinRow} for the activity ${this.getActivityLabel(timeactivity.activity)}`)
    //             return false
    //           }
    //         }
    //         return true
    //       })
    //   }
    //   return true
    // },
  },
}
</script>
